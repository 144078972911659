import React, { Component } from 'react'

import { isMobile } from '../utils'


export default class Background extends Component {
  render() {
    let background
    if (isMobile()) {
      background = <div key="home-background" className="static-background" />
    } else {
      background = (
        <div key="home-background" className="video-background">
          <div className="video-foreground">
            <iframe title="framealive-background" height="100%" width="100%" src="https://www.youtube.com/embed/OOl6M72bG7Y?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1"w
              frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
      )
    }
    return background
  }
}
