import React, { Component } from 'react'


import API from '../api'
import downLeft from '../static/img/downLeft.png'
import downRight from '../static/img/downRight.png'
import upLeft from '../static/img/upLeft.png'
import upRight from '../static/img/upRight.png'
//import Webcam from 'react-webcam';
//import ReactPlayer from 'react-player'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
//import cameraErrorImg from '../static/img/cameraError.jpg'
import closeVideo from '../static/img/closeVideo.png'

import VideoPlayer from '../components/VideoPlayer.js';

export default class ScannerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videoUrl: undefined,
      showVideo: false,
      showError: false,
      showLoading: false,
      cameraError:false,
      videoPlaying: false,
      muteVideo: true,
      fullscreen: true,
      cta: undefined,
      showCta: false,
      ctaFontSize: '30px',
    }
  }

no
  setRef = webcam => {
      this.webcam = webcam;
    };

    onTakePhoto = (dataUri) =>{
       // Do stuff with the dataUri photo...
       //window.alert(dataUri);
     }
     onCameraError =(error) =>{
       //this.setState({showError: true})
    //console.error('onCameraError', error);
//window.alert("CAMERA ERROR");
    // eslint-disable-next-line
    const errorStr = new String(error);
    if (!(errorStr.indexOf('Error: no stream to stop!')!==-1)){
      this.setState({cameraError: true})
      window.open('http://bit.ly/2DbPaQJ', "_blank");
    //  window.alert("NO PUDO CARGAR LA CAMERA");
    }
  //  window.alert(error);
  }

  onCameraStart =(stream)=> {
    //console.log('onCameraStart');
    //window.alert("CAMERA START");
  }

  onCameraStop =() =>{
    //console.log('onCameraStop');
  //  window.alert("CAMERA STOP");
  }

    capture = (dataUri) => {
      // eslint-disable-next-line
      //const imageSrc = this.webcam.getScreenshot();

      if (!this.state.showLoading){
        this.setState({showError: false})
          this.setState({showLoading: true})
          this.setState({showVideo: false})
          this.setState({videoPlaying: false})
          //window.alert(" Sacando foto");
          this.setState({showError: false})

        //  window.alert(imageSrc);
          API.getStreamingUrl(dataUri).then(resp =>  {
            if (resp.ok) {
              //window.alert('resp ok')
              //window.alert(resp)

              return resp.json()
            } else {
            /////  window.alert('resp exception')
            /////  window.alert(resp)
              throw resp
            }
          }).then(promo => {
            //window.alert('video url =')
            //window.alert(promo.streamingUrl)
            this.setState({showVideo: true})
            //var aux = promo.cta;
            //window.alert(aux);
            this.setState({videoUrl: promo.streamingUrl});
            if (promo.cta !== ''){
              var jsoncta = JSON.parse(promo.cta)

              if (jsoncta.buttonFontSize ==='LARGE'){
                //window.alert("large");
                this.setState({ctaFontSize: '40px'});
              }else if (jsoncta.buttonFontSize === 'MEDIUM'){
                //window.alert("medium");
                this.setState({ctaFontSize: '30px'});
              }else if (jsoncta.buttonFontSize === 'SMALL'){
                //window.alert("small");
                this.setState({ctaFontSize: '20px'});
              }
              //window.alert(this.state.ctaFontSize);
              this.setState({cta: jsoncta});
            }
          }).catch(
            error => {
              this.setState({showError: true})
              this.setState({showVideo: false})
              /////window.alert('ltu error')
              this.setState({showLoading: false})
              /////window.alert(error)
            }
          )
      }
    };

    handleCameraError = () => {
    //  window.alert("ERROR DE CAMERA");
    };

    handleVideoEnd = () => {
      //window.alert("TERMINO EL VIDEO");
      this.setState({videoPlaying: false})
      this.setState({videoUrl: ''})
      this.setState({cta: ''})
      this.setState({showLoading: false})
      this.setState({showVideo: false})

    };
    imageClick = () => {
          //window.alert("ERRO CAMERA IMAGE CLICK");
          window.open('http://bit.ly/2DbPaQJ', "_blank");
    }
    videoClick = muted => {
      /////  window.alert("MUTE/UNMUTE");
        this.setState({muteVideo: muted});
    }

    videoPauseClick = () => {
        /////window.alert("VIDEO PAUSA/PLAY");
        this.setState({videoPlaying: !this.state.videoPlaying})
    }
    handleCloseVideo = () => {
        /////  window.alert("CLOSE VIDEO");
        this.setState({videoPlaying: false})
        this.setState({videoUrl: ''})
        this.setState({showLoading: false})
        this.setState({showVideo: false})
        if ((this.state.cta !== '') && ((this.state.cta !== undefined))){
            //window.alert("CTA CON INFO");
            this.setState({showCta: true});
            this.timeoutHandle = setTimeout(()=>{
              if (this.state.showCta){
                  this.setState({showCta: false});
                  this.setState({cta: ''});
              }
            }, 5000);
        }
    }

    handleCallToAction= () => {
      //window.alert(this.state.cta.buttonUrl);
      window.open(this.state.cta.buttonUrl);
    }

    handleCloseCta= () => {
      this.setState({showCta: false});
      this.setState({cta: ''});
    }

    handleLoading = () => {
          /////window.alert("HIDE LOADING");
          this.setState({showLoading: false});
    }

    handleVideoReady = () => {
      //window.alert("Esta START");
          //this.setState({videoPlaying: true})

    }

    handleVideoEndBuffer = () => {
        //  window.alert("TERMINO EL BUFFERING");
      //  this.setState({showLoading: false})
    }

    handleVideoStart = () => {
      /////window.alert("PLAY TRUE / LOADING FALSE");
      this.setState({showLoading: false})
      this.setState({videoPlaying: true})
    }

    handleErrorVideo = (error) => {
      /////window.alert("video error");
      /////.alert(error);

    }

    render() {
  //    const videoConstraints = {
    //    facingMode: { exact: "environment" }
    //  };

      return (
        <div className='scanGlobal'>
            {
                !this.state.cameraError && !this.state.showVideo && !this.state.showCta? (

                <Camera
                  onTakePhoto = { (dataUri) => { this.capture(dataUri); } }
                  onCameraError = { (error) => { this.onCameraError(error); } }
                  idealFacingMode = {FACING_MODES.ENVIRONMENT}
                  idealResolution = {{width: 640, height: 480}}
                  imageType = {IMAGE_TYPES.JPG}
                  imageCompression = {0.97}
                  isMaxResolution = {false}
                  isImageMirror = {false}
                  isSilentMode = {true}
                  isDisplayStartCameraError = {false}
                  isFullscreen = {true}
                  sizeFactor = {1}
                  onCameraStart = { (stream) => { this.onCameraStart(stream); } }
                  onCameraStop = { () => { this.onCameraStop(); } }

                />
              ):null
            }
            {
              (!this.state.showVideo && !this.state.showLoading && !this.state.showCta)? (
                <div>
                  <img src={upLeft} style={{position:'fixed',width: '50px', top:'30px',left:'30px', height: '50px',zIndex:'3', opacity:'0.6'}} alt="" />
                  <img src={upRight} style={{position:'fixed',width: '50px', top:'30px',right:'30px', height: '50px',zIndex:'3', opacity:'0.6'}} alt="" />
                  <img src={downLeft} style={{position:'fixed',width: '50px', bottom:'30px',left:'30px', height: '50px',zIndex:'3', opacity:'0.6'}} alt="" />
                  <img src={downRight} style={{position:'fixed',width: '50px', bottom:'30px',right:'30px', height: '50px',zIndex:'3', opacity:'0.6'}} alt="" />
                </div>
              ):null
            }
          {/*  {
              this.state.cameraError ? (
                <img src={cameraErrorImg} onClick={() => this.imageClick()} style={{position:'fixed',width: '100%', height: '100%'}} alt="" />
              ):null
            }*/}
            {
            this.state.showLoading ? (
              <div  className='loading' alt="" />
            ):null
            }
            {
            this.state.showError ? (
              <div className="notRecognizeImage">Ups! No encontramos esa foto.</div>):null
            }
            {
            this.state.showError ? (
              <div className="tryAgainMsg">Busca fotos vinculadas con FrameALIVE e intentalo nuevamente</div>):null
            }
            <div className='player-wrapper'>
              {
                (this.state.showVideo)? (
                  <div>
                  <img src={closeVideo} style={{position:'fixed',width: '40px', top:'10px',right:'10px', height: '40px',zIndex:'15', opacity:'0.6'}} onClick={() => this.handleCloseVideo()} alt="" />

                  <VideoPlayer
                    src={this.state.videoUrl}
                    hideLoading={this.handleLoading}
                    onClose={this.handleCloseVideo}
                  />

                 </div>
               ):null
              }
            </div>
            {
            this.state.showCta ? (
              <div style={{width: '100%', height: '100%',backgroundImage: 'url('+ this.state.cta.backgroundImage+')',display:'flex',alignItems:'center', justifyContent:'center',backgroundSize:'cover', backgroundRepeat:'no-repeat',backgroundAttachment:'fixed'}}>
              <img src={closeVideo} style={{position:'fixed',width: '40px', top:'10px',right:'10px', height: '40px',zIndex:'15', opacity:'0.6',color:this.state.cta.buttonBackground}} onClick={() => this.handleCloseCta()} alt="" />
                <button style={{display:'flex',alignSelf:'middle', color: this.state.cta.buttonColor, backgroundColor: this.state.cta.buttonBackground, borderRadius: '20px', height: '85px',width: '290px', justifyContent: 'center',border:'none',fontSize:this.state.ctaFontSize}} onClick={() => this.handleCallToAction()}>
                  {this.state.cta.buttonCaption}
                </button>
              </div>):null
            }
        </div>
      );
    }}
