import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import HomePage from '../pages/HomePage'
import UserVideosPage from '../pages/UserVideosPage'
import TermsPage from '../pages/TermsPage'
import ScannerPage from '../pages/ScannerPage'
import VideoPlayerPage from '../components/VideoPlayer'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import { Register, Activate, Login, ResetPassword } from '../pages/LoginPage'
//import Navigation from '../components/Navigation'
//import Footer from '../components/Footer'


/*
 * Main application container.
 */
export default class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/mycollection" component={UserVideosPage} />
            <Route exact path="/terms" component={TermsPage} />
            <Route exact path="/privacy" component={PrivacyPolicyPage} />
            <Route exact path="/scanner" component={ScannerPage} />
            <Route exact path="/videoPlayer" component={VideoPlayerPage} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/activate/:token" component={Activate} />
            <Route exact path="/reset" component={ResetPassword} />
            <Route exact path="/reset/:token" component={ResetPassword} />
            <Route component={HomePage} />
          </Switch>

        </div>
      </Router>
    )
  }
}
