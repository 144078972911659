import React, { Component } from 'react'
import videojs from 'video.js'

import 'video.js/dist/video-js.css'
/*import closeVideo from '../static/img/closeVideo.png'
import volumeVideo from '../static/img/volume.png'
import muteVideo from '../static/img/mute.png'
*/
import volumeVideo from '../static/img/volume.png'
import muteVideo from '../static/img/mute.png'

export default class VideoPlayer extends Component {
/*componentWillMount(){
  this.state = {
    mute: true
  }
}*/

constructor(props) {
  super(props)
  this.state = {
    mute: true
  }
}

  componentDidMount(prevProps, prevState) {

    const options = {
      responsive: 'true',
      autoplay: 'muted',
      controls: 'false',
      fullscreen: "{options: {navigationUI: 'show'}",
      sources: [{
        src: this.props.src,
        type: 'application/x-mpegURL'
      }]
    }
    /////window.alert('EN EL PLAYER VIEJO')
    /////window.alert(this.props.src)
    this.player = videojs(this.videoNode, options);
    //window.alert('muted = ');
    //window.alert(this.props.muted)
    //this.setState({mute:this.props.muted});
    this.player.muted(true);
    this.player.on('finish',this.handleCloseVideo);
    this.player.on('webkitExitFullScreen', function() {
      if (this.player.isFullscreen()){
        /////  window.alert('video exit fullscreen!');
      }else{
        /////  window.alert('enter fullscreen!');
      }



    });




   this.player.on('ready', function() {
    /////  window.alert('VIDEO READY');
    });

    this.player.ready(this.handleReady);

    this.player.on('error', function(e) {
    /////  window.alert('error en el reproductor: ');
    //  var error2 = e;
    /////  window.alert('error del handler!', error2.message);
    });

    this.player.error(function(e) {
    /////  window.alert('error funcion en el reproductor: ');
    /////  window.alert(e);
    });


  }

  componentWillUnmount() {
    if (this.player) {
    /////  window.alert("disposeeeee");
      this.player.dispose()
    }
  }

  componentDidUpdate(prevProps, prevState) {
  /////  window.alert("CHEQUEO SI CAMBIARON LAS PROPS");
    if ((prevProps.src !== this.props.src) && (this.props.src !== '')) {
    /////  window.alert("CAMBIARON PROPS - DOY PLAY");
      this.player.src(this.props.src)
      this.player.play()
      this.props.hideLoading();
    }
  }


    handleReady = () => {
    /*this.on('ended', function() {
      window.alert('video is done!');
      this.dispose();
    });*/
    this.player.on('timeupdate', this.handleTimeupdate);
  }

  handleTimeupdate = () => {
  /*  window.alert("timeupdate");
    window.alert(this.player.currentTime()/this.player.duration());
    window.alert(this.player.duration());*/
    var porcentaje = this.player.currentTime()/this.player.duration();
  //  window.alert(porcentaje);
    if (porcentaje>0.99) {
      //window.alert("mayor que 99%");
      this.handleCloseVideo();
    }
  }


  handleCloseVideo = () => {
    //this.player.requestFullscreen();
    /////window.alert("HANDLECLOSE VIDEO");
    if (this.player) {
      /////window.alert("disposeeeee");
      this.player.dispose()
      this.props.onClose()
    }
  }

  videoClick = () => {
      /////window.alert("MUTE/UNMUTE");
      if (this.player.muted()){
        this.setState({mute:false})
        this.player.muted(false);
        //this.props.muted(false);
      }else{
        this.setState({mute:true})
        this.player.muted(true);
        //this.props.muted(true);
      }
  }

  render() {
    return (
      <div>
      {
        (this.state.mute? (
          <img src={muteVideo} style={{position:'fixed',width: '40px', top:'10px',left:'10px', height: '40px',zIndex:'15', opacity:'0.6'}} onClick={() => this.videoClick()} alt="" />
        ):(<img src={volumeVideo} style={{position:'fixed',width: '40px', top:'10px',left:'10px', height: '40px',zIndex:'15', opacity:'0.6'}} onClick={() => this.videoClick()} alt="" />)
       )
      }
          <div className="react-player" style={{width: '100%',height: '100%'}}>
            <video ref={ node => this.videoNode = node } playsInline preload="auto" crossOrigin="true" autoPlay style={{width: '100%',height: '100%'}} /*onEnded={this.handleCloseVideo}*/ ></video>
          </div>
      </div>
    )
  }
}
