import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import Raven from 'raven-js'

import settings from './settings'

import App from './containers/App'

import './static/vendor/bootstrap/css/bootstrap.min.css'
import './static/vendor/font-awesome/css/font-awesome.min.css'
import './styles/creative.css'
import './styles/main.css'


// Setup Sentry
Raven.config(settings.SENTRY_DSN, { environment: settings.SENTRY_ENV }).install();


class Root extends Component {
  render() {
    return <App />
  }
}

ReactDOM.render(
  <Root />,
  document.getElementById('root')
)
