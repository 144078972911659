import MobileDetect from 'mobile-detect'


export const uploadToS3 = (url, opts={}, onProgress) => {
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest()
    xhr.open(opts.method || 'get', url)
    for (var k in opts.headers || {})
      xhr.setRequestHeader(k, opts.headers[k])
    xhr.onload = e => res(e.target.responseText)
    xhr.onerror = rej
    if (xhr.upload && onProgress)
      xhr.upload.onprogress = onProgress
    xhr.send(opts.body)
  })
}

export const isMobile = () => {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.mobile()
};


export const isAndroid = () => {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.os() === 'AndroidOS'
}
