import React, { Component } from 'react'

import Modal from 'react-modal'


export default class Popup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      content: undefined,
      closable: false,
    }
  }

  show = (content, closable=false) => {
    this.setState({ show: true, content, closable})
  }

  hide = () => {
    this.setState({ show: false, content: undefined})
  }

  render() {
    const style = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        zIndex: 999,
      }
    }
    return (
      <Modal
        style={style}
        ariaHideApp={false}
        isOpen={this.state.show}
        onRequestClose={this.hide}
      >
        { this.state.closable &&
          <div className="popup-close" onClick={this.hide}> X </div>
        }
        <div className="popup-body">
          { this.state.content }
        </div>
      </Modal>
    )
  }
}
