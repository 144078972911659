import React, { Component } from 'react'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

export default class FAQPage extends Component {
  render() {
    return (
      <div className="text-container">
         <Navigation/>
         <p>
          TERMS AND CONDITIONS FOR ALL USERS<br/><br/>By downloading, installing, or using the FRAME ALIVE app or web site you are
          unconditionally agreeing to be bound and obligated by the following terms and<br/>
          conditions of use (&quot;Terms of Use&quot;).<br/>
          Terms<br/>
          <br/><br/>
          • All users must be 14 years of age or older to use the FRAMEALIVE app.<br/>
          • The user is the only responsible for its behavior and any data,
          text, information, screen names, graphics, photos, profiles, audio and video
          clips, links (&quot;Content&quot;) that he/she submits, posts, and displays on the
          FRAME ALIVE app or web site.<br/>
          • The user accepts that through its device camera, photographs
          are sent to the FRAME ALIVE search system in order to match them with
          searched videos.<br/>
          • User must not post nude, partially nude, sexually explicit or
          suggestive photos or violent photos. Nor must he/she post logos,
          Trademarks, or photos or videos of third parties without their permission.<br/>
          • User is responsible for any activity that occurs under its screen
          name.<br/>
          • Any time the FRAME ALIVE app is open in the user´s device
          in the scan mode, it will be sending to FRAME ALIVE its photographs.<br/>
          • User must never use the FRAME ALIVE app for any illegal or
          unauthorized purpose. All international users agree to comply with all local
          laws regarding online conduct, acceptable content, copyright laws,
          trademark laws, and any other applicable laws.<br/>
          • User must never modify, adapt or hack FRAME ALIVE app. In
          order to modify, translate, publish, broadcast, transmit, distribute, perform,
          display, or sell any content appearing on or through the FRAME ALIVE app
          or the web site User must obtain express authorization form FRAME
          ALIVE.<br/>
          • It´s forbidden for all users to transmit any worms or viruses or
          any code of a destructive nature.<br/>
          • User hereby sates that he/she understands and agrees that
          FRAME ALIVE app is not responsible for the Content posted on its app and
          that he/she may be nonetheless exposed to such materials while using the
          app, accepting hereby the risks of using the FRAME ALIVE app.<br/>
          • Any violation of any of these agreements will result in the
          termination of the user´s FRAME ALIVE account.<br/>
          • Notwithstanding the aforementioned, FRAME ALIVE reserves
          the right to modify or terminate any user´s account and/or the FRAME
          ALIVE service for any reason, without notice at any time.<br/>
          • FRAME ALIVE will keep the uploaded photographs or videos
          in its servers, and will reasonably use those photographs or videos at any
          time.<br/>
          • FRAME ALIVE will not be obligated to notify the users when
          a photograph or video is deleted from the FRAME ALIVE servers.<br/>
          • The user grants FRAME ALIVE a non-exclusive, fully paid
          and royalty-free, worldwide, limited license to use, modify, delete from, add
          to, publicly perform, publicly display, reproduce and translate the
          photographs and videos he/she uploads to the app or web site.<br/>
          • The user must not use or upload to the FRAME ALIVE app or
          web site, photographs or videos if he/she does not own the copyrights of said
          photographs or videos.<br/>
          • User must be the owner of the photographs or videos he/she
          uploads to the app, or otherwise have the right to grant the license described
          on clause 16 of this Terms.<br/>
          • FRAME ALIVE will not be responsible for any loss of
          photographs and/or videos. User hereby agrees to pay for all royalties, fees,
          and any other monies owing any person by reason of any content uploaded
          to the app.<br/>
          • FRAME ALIVE will be able at its sole discretion to remove
          any content and/or user accounts containing any kind of content that
          FRAME ALIVE understands and determines are offensive, unlawful,
          threatening, defamatory, obscene, or otherwise objectionable or violates any
          party&#39;s intellectual property or these Terms of Use.<br/>
          • We reserve the right to reclaim usernames on behalf of
          businesses or individuals that hold legal claim or trademark on those
          usernames.<br/>
          • FRAME ALIVE app and trademarks are protected by
          copyright, trademark, patent, trade secret and other applicable laws.<br/>
          • Although the FRAME ALIVE app will be normally available,
          there will be occasions when the app will be interrupted for scheduled
          maintenance or upgrades, for emergency repairs, or due to any kind of
          failure that are beyond the control of FRAME ALIVE.<br/>
          • FRAME ALIVE reserves the right to alter these Terms of Use
          at any time for any reason, notifying, in necessary, the user of said changes.<br/><br/>
        </p>
        <Footer/>
      </div>
    )
  }
}
