const settings = {}

settings.API_URL = process.env.REACT_APP_API_URL

settings.MAX_VIDEO_SIZE = 1
settings.MAX_IMAGE_SIZE = 4

settings.MAX_VIDEOS_PER_PAGE = 20

settings.PAYPAL_CLIENT = process.env.REACT_APP_PAYPAL_CLIENT
settings.PAYPAL_KEY = process.env.REACT_APP_PAYPAL_KEY

settings.PLANS = {
  'basic': {
    'name': 'Basic',
    'price': 8,
    'description': '1 FrameALIVE'
  },
  'plus': {
    'name': 'Plus',
    'price': 75,
    'description': '30 FrameALIVEs'
  },
  'professional': {
    'name': 'Professional',
    'price': 190,
    'description': '120 FrameALIVEs'
  }
}

// Sentry config
settings.SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
settings.SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV

export default settings
