import React, { Component } from 'react'
import { Link } from 'react-router-dom'


export default class Footer extends Component {
  render() {
    return (
      <footer>
        <ul className="navbar-nav bottomulleft">
          <li className="nav-item bottomlileft">
            <Link className="nav-link js-scroll-trigger" to="/terms" style={{padding: 0}}>© FrameALIVE, Inc. Patent pending</Link>
          </li>
          <li className="nav-item bottomlileft">
            <a className="nav-link js-scroll-trigger noShow" href="/terms" style={{padding: 0, paddingLeft: '15px'}}>Terms & conditions</a>
          </li>
          <li className="nav-item bottomlileft">
            <a className="nav-link js-scroll-trigger noShow" href="/privacy" style={{padding: 0, paddingLeft: '15px'}}>Privacy Policy</a>
          </li>
        </ul>
        <ul className="navbar-nav bottomulright">
          <li className="nav-item bottomliright">
            <a href="mailto:info@framealive.com">
              <i className="fa fa-envelope-o fa fa-inverse" aria-hidden="true"></i>
            </a>
          </li>
          <li className="nav-item bottomliright">
            <a target="_blank" href="https://www.instagram.com/framealive/?hl=es" rel="noopener noreferrer">
              <i className="fa fa-instagram fa fa-inverse" aria-hidden="true"></i>
            </a>
          </li>
          <li className="nav-item bottomliright">
            <a target="_blank" href="https://www.facebook.com/framealive/" rel="noopener noreferrer">
              <i className="fa fa-facebook fa fa-inverse" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </footer>
    )
  }
}
