import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

import API from '../api'
import Auth from '../auth'

import logo from '../static/img/Logo.png'


class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileMenu: false,
      remainingCount: undefined,
    }
  }

  componentWillMount() {
    API.fetchRemainingCount().then(
      resp => resp.json()
    ).then(
      resp => this.setState({remainingCount: resp.data})
    )
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
   }

   handleClickOutside = e => {
     if (this.nav && !this.nav.contains(e.target)) {
       this.setState({ showMobileMenu: false })
     }
   }

  handleMenuClick = e => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu })
  }

  handleLogout = e => {
    Auth.removeToken()
  }

  render() {
    const token = Auth.getToken()

    let login
    if (!token) {
      login = (
        <Link className="nav-link js-scroll-trigger" to="/login">
          <button id="nav-login" className="btnLogin"> Log in </button>
        </Link>
      )
    } else {
      login = (
        <div className="nav-link js-scroll-trigger" to="/">
          <button id="nav-logout" className="btnLogin" onClick={this.handleLogout}> Log out </button>
          <div className="remaining-count">
            <Link to="/plans"> {this.state.remainingCount} FrameALIVEs available! </Link>
          </div>
        </div>
      )
    }

    const mobileMenuClass = 'collapse navbar-collapse' + (
      this.state.showMobileMenu ? ' show' : ''
    )

    const links = [
      { to: '/', text: 'Create Now!', main: true },
      { to: '/mycollection', text: 'My Collection', main: false },
      { to: '/scanner', text: 'Scanner', main: false },
    ]

    return (
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav" ref={node => this.nav = node}>
         <div className="container">
           <a className="navbar-brand js-scroll-trigger" href="https://www.framealive.com">
             <img src={logo} alt="" />
           </a>
           <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive"
             aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" onClick={this.handleMenuClick}>
             <span className="navbar-toggler-icon"></span>
           </button>
           <div className={mobileMenuClass} id="navbarResponsive">
             <ul className="navbar-nav ml-auto" onClick={this.handleMenuClick}>

              {links.map(l => {
                let linkClassName = "nav-link js-scroll-trigger superMenu"
                if (l.main) {
                  linkClassName += " main"
                }
                if (l.to === this.props.history.location.pathname) {
                  linkClassName += " selected"
                }
                return (
                  <li className="nav-item">
                    <Link className={linkClassName} to={l.to}> {l.text}</Link>
                  </li>
                )
              })}
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger superMenu" href="https://landing.framealive.com/plans">Plans</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger superMenu" href="https://landing.framealive.com/">Learn More</a>
              </li>

              <li className="nav-item login-nav">
                { login }
              </li>
             </ul>
           </div>
         </div>
       </nav>
    )
  }
}

export default withRouter(Navigation)
