class Auth {
  constructor() {
    this.sessionToken = null
    this.storage = window.localStorage
  }

  isLocalStorageNameSupported = () => {
    let testKey = 'test'
    try {
      this.storage.setItem(testKey, '1')
      this.storage.removeItem(testKey)
      return true
    } catch (error) {
      return false
    }
  }

  setToken = (token) => {
    document.cookie = `token=` + token + `; expires=Mon, 17 Sep 2030 00:00:00 UTC; path=/`
    if (this.isLocalStorageNameSupported()) {
      this.storage.token = token
    } else {
      this.sessionToken = token
    }
  }

  removeToken = () => {
    if (this.isLocalStorageNameSupported()) {
      this.storage.removeItem('token')
      document.cookie = `token='' expires=-1; path=/`
    }
  }

  getToken = () => this.sessionToken || this.storage.token
}


export default new Auth()
