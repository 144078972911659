import React, { Component } from 'react'

import Background from '../components/Background'
import Uploader from '../components/Uploader'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'


export default class HomePage extends Component {
  render() {
    return (

      <div className="landing">
      <Navigation/>
        <Background />
        <div key="home-main" className="container-fluid">
          <div className="row">
            <Uploader history={this.props.history} />
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}
