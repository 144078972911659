import React, { Component } from 'react'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

export default class PrivacyPolicyPage extends Component {
  render() {
    return (
      <div className="text-container">
        <Navigation/>
        <h5> Introduction </h5>
        <p>
          At FrameALIVE (“FrameALIVE,” “we,” “us,” or “our”), protecting your privacy is really important to us. We care about being transparent, and we’re committed to being upfront about our privacy practices and how exactly we use your personal information. Our objective is that this Privacy Policy (“Policy”) will explain what we mean in further detail below.
        </p>

        <h5> About this Policy </h5>
        <p>
          This Policy relays the essential details pertaining to your personal data used by FrameALIVE. It applies to all FrameALIVEs services, defined in our Terms and Conditions (“Terms”).
        </p>
        <p>
          In time, we may develop new products or offer new services. If these new products and services cause any change to the way in which we collect or process your personal data, we will provide you with more information on the additional terms and policies.
        </p>
        <p>
          The aim of this Policy is to:
        </p>
        <p>
          1. Ensure that you understand what personal data we collect about you, the reasons why we collect it, and who we share it with;
        </p>
        <p>
          2. Explain your rights and choices in relation to the personal data we collect and process about you and how we will protect your privacy.
        </p>
        <p>
          We need to process your personal information in order to run our business and provide you with these Services. By accepting this Policy and the Terms, you are confirming that you have read and understand this Policy including how and why we use your information. If you do not accept this Policy, please remember that it is your choice whether you want to use FrameALIVEs’ services.
        </p>

        <h5> When do we collect your personal data and what exactly do we collect? </h5>
        <p>
          We collect personal information in the following ways:
        </p>
        <p>
          1. When you register with FrameALIVE
        </p>
        <p>
          2. When you use any of our products or services
        </p>
        <p>
          We collect personal information when you register with FrameALIVE and when you use any of our products or services. Personal information is any information about you that is personally identifiable like your name, address, email address or phone number, Facebook Connect sign-in information (if applicable), and any other information that is not otherwise publicly available. If you order products or pay for services, we will need your billing name and address, as well as the credit card number and expiration date.
        </p>

        <h5> What do we use your personal data for? </h5>
        <p>
          We use your personal information for the following general purposes:
        </p>
        <p>
          1. To fulfill your requests for services, administrative purposes;
        </p>
        <p>
          2. To improve our services;
        </p>
        <p>
          3. To customize the advertising and content you see; and
        </p>
        <p>
          4. To contact you about new products, sales, and announcements.
        </p>
        <p>
          We also use anonymous information, such as that pertaining to web pages you've viewed, in order to analyze trends, track user's movements, provide a better website experience, and gather broad demographic information for aggregate use. We do not use this data in a manner that would identify you personally.
        </p>

        <h5> Sharing your personal data </h5>
        <p>
          Every now and then, FrameALIVE will invite customers to take part in surveys, promotions and contests. The information we collect from these surveys, promotions and contests may be matched with individual customer accounts and used by FrameALIVE to improve your overall customer experience, we do not share this information with any third parties.
        </p>
        <p>
          Under the following circumstances, we may be required to share your personal information:
        </p>
        <ul>
          <li>
            If we respond to subpoenas, court orders or legal process, or if we need to establish or exercise our legal rights or defend against legal claims;
          </li>
          <li>
            If we believe it is necessary to share information in order to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of the FrameALIVE Terms & Conditions or as otherwise required by law.
          </li>
          <li>
            Restrict or inhibit any other user from using the site, including, without limitation, by means of "hacking" or defacing any portion of the site;
          </li>
        </ul>

        <h5> Data access, retention & deletion </h5>
        <p>
          You have the right and ability to edit your member account information on our website at any time. You can opt in or out of receiving future communications of special offers and new product information, or you can unsubscribe by following instructions contained in the messages you receive. We do reserve the right to send you certain communications relating to the services FrameALIVE provide, such as service announcements and administrative messages, that are considered part of your account membership. We do not offer you the opportunity to opt-out of receiving those messages.
        </p>
        <p>
          We keep your personal data only as long as necessary to provide you with services that we provide. If you request, we will delete your personal data so that it no longer identifies you, unless, we are legally allowed or required to maintain certain personal data.
        </p>

        <h5> Children's Information </h5>
        <p>
          We do not target our website or products to children, and we will not knowingly collect personally identifiable information from children without obtaining prior verifiable parental consent. If we become aware that a subscriber is under the age of 13 and has registered without prior verifiable parental consent, we will remove their personally identifiable information from our files.
        </p>

        <h5> The safety of your personal data </h5>
        <p>
          We are committed to protecting our users’ personal data. We use a secure server (SSL) to encrypt your personal financial information. We use other physical, electronic and procedural safeguards to protect the security and confidentiality of the personal information we have collected, and to prevent unauthorized access to or disclosure of your personal information.
        </p>

        <h5> Third Party Sites </h5>
        <p>
          Our websites may contain links to other websites for news and other information. Our Privacy Policy only applies to the website of FrameALIVE, and we are not responsible for the privacy practices or the content of other websites which are linked to or integrated with FrameALIVE. You should check the privacy policies of those sites before providing your personal information to them. See our Terms and Conditions for additional information.
        </p>

        <h5> Our Cookie Policy </h5>
        <p>
          Like many websites, we use cookies to collect anonymous visitor information. Cookies contain information that is transferred to your computer’s hard drive and stored in your browser’s cache. If you disable cookies in your web browser, some features of our website will not function properly.
        </p>

        <h5> Questions </h5>
        <p>
          If you have questions about this Privacy Policy, you can contact us at  +1(442)200 6971 or contact us <a href="mailto:info@framealive.com" target="_top" style={{color: '#007bff'}}>info@framealive.com</a>.
        </p>
        <p>
          You may access and use the site solely for your personal, noncommercial use. Except as expressly authorized hereunder, the site may not be reproduced, duplicated, copied, sold, resold, visited, reverse-engineered or otherwise exploited for any commercial purpose without FrameALIVEs prior written authorization. We reserve the right to alter or discontinue the site, in whole or in part, at any time in FrameALIVEs sole discretion.
        </p>
        <Footer/>
      </div>
    )
  }
}
