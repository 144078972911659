import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import API from '../api'
import Auth from '../auth'
import settings from '../settings'

import Popup from '../components/Popup'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

//import VideoList from '../components/VideoList'
// eslint-disable-next-line
//import VideoListItem from '../components/VideoListItem'
// eslint-disable-next-line
//import VideoPlayer from '../components/VideoPlayer'

export default class UserVideos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      videos: [],
      selected: undefined,
      loading: true,
      downloadProgress: undefined,
    }
  }

  componentDidMount() {
    this.loadPage(1)
  }

  loadPage = page => {
    this.setState({ loading: true, selected: undefined })
    API.fetchVideos(page).then(
      resp => resp.json()
    ).then(resp => (
      this.setState({
        page,
        videos: resp.visuals,
        loading: false,
      })
    ))
  }

  onPageChange = d => () => {
    const newPage = d === 'next' ?  this.state.page + 1 : this.state.page - 1
    if (newPage < 1) {
      return
    }
    this.loadPage(newPage)
  }

  onSelect = id => () => {
    if (this.state.selected === id) {
      this.setState({ selected: undefined })
    } else {
      this.setState({ selected: id })
    }
  }

  onDelete = video => () => {
    const onConfirmation = () => {
      // Delete before calling API to avoid UI delay.
      // TODO: Cactch and notify user if the deletion filed.
      const idx = this.state.videos.findIndex(v => v.id === video.id)
      this.setState({
        videos: [
          ...this.state.videos.slice(0, idx),
          ...this.state.videos.slice(idx + 1),
        ]
      })
      API.deleteVideo(video.id, video.title)
      this.popup.hide()
    }

    const confirmationPopup = (
      <div className="delete-confirmation">
        <div className="delete-message">
          Are you sure you want to delete the following FrameALIVE?
        </div>

        <div className="delete-fa-details">
          <div> <div className="field"> Video </div> {video.original_image_name} </div>
          <div> <div className="field"> Image </div> {video.original_video_name} </div>
        </div>

        <div className="delete-actions">
          <div className="simple-btn" onClick={this.popup.hide}> Cancel </div>
          <div className="simple-btn" onClick={onConfirmation}> Confirm </div>
        </div>
      </div>
    )

    this.popup.show(confirmationPopup, true)
  }

  downloadFile = (url, filename) => {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'

    xhr.onload = function(e) {
      if (this.status !== 200) return;

      const blob = new Blob([this.response], {type: this.responseType})

      const l = document.createElement('a')
      l.download = filename
      l.href = window.URL.createObjectURL(blob)
      l.onclick = function(e) {
          const that = this
          setTimeout(function() {
              window.URL.revokeObjectURL(that.href)
          }, 1500)
      }
      l.click()
      l.remove()
    }

    this.setState({ downloadProgress: 0 })
    const self = this
    xhr.onprogress = function(e) {
      if (this.readyState === 3) {
        self.setState({ downloadProgress: e.loaded / e.total * 100 })
      } else if (this.readyState === 4) {
        self.setState({ downloadProgress: undefined })
      }
    }

    xhr.send()
  }

  onDownloadImage = v => () => {
    this.downloadFile(`${settings.API_URL}/api/download_image/${v.id}`, v.original_image_name)
  }

  onDownloadVideo = v => () => {
    this.downloadFile(v.videoOriginal, v.original_video_name)
  }

  render() {
    // eslint-disable-next-line
    const { videos, selected, page, loading } = this.state
    const token = Auth.getToken()

    if (!token) {
      return <div className="non-authenticated-message">
        <Navigation/>
        <Link to="/login">Log in</Link> to view your FrameALIVEs
        <Footer/>
      </div>
    }

    if (loading) {
      return (
        <div className="framealive-collection">
        <Navigation/>
          <div className="loading"> Loading... </div>
        <Footer/>
        </div>
      )
    }

    if (!videos.length) {
      return <div className="framealive-collection"><Navigation/> No videos! <Footer/></div>
    }

    const playVideo = videoUrl => () => {
      const videoPopup = (
        <div>
          <video src={videoUrl} autoPlay style={{maxWidth: '100%', maxHeight: '500px'}} controls> </video>
        </div>
      )
      this.popup.show(videoPopup, true)
    }

    let actions
    if (this.state.selected) {
      const v = this.state.videos.find(v => v.id === this.state.selected)
      // TODO: Append "_fa" string to filenames.

      actions = (
        <div className="framealive-actions">
          <div className="action" onClick={this.onDownloadImage(v)}> Download image </div>
          <div className="action" onClick={this.onDownloadVideo(v)}> Download video </div>
          <div className="action" onClick={this.onDelete(v)}> Delete </div>
        </div>
      )
    }

    let downloading
    if (this.state.downloadProgress !== undefined) {
      downloading = (
        <div> Downloading {Math.round(this.state.downloadProgress)}% </div>
      )
    }

    let videoList = (
      <div className="framealive-list">
        {videos.map(v => {
          const itemClass = 'framealive-item' + (this.state.selected === v.id ? ' selected' : '')
          return (
            <div className={itemClass} onClick={this.onSelect(v.id)}>
              <div className="framealive-thumbnail" onClick={playVideo(v.videoOriginal)}>
                <img src={v.thumbnail} alt="" />
              </div>
              <div className="framealive-files">
                <div className="framealive-image-file">
                  <i className="fa fa-file-image-o framealive-value-icon" />
                  {v.original_image_name}
                </div>
                <div className="framealive-video-file">
                  <i className="fa fa-file-video-o framealive-value-icon" />
                  {v.original_video_name}
                </div>
              </div>
              <div className="framealive-details">
                <div className="framealive-creation-date">
                  <i className="fa fa-calendar-o framealive-value-icon" />
                  {moment(v.created_at).fromNow()}
                </div>
                <div className="framealive-match-count">
                  <i className="fa fa-eye framealive-value-icon" />
                  {v.match_count}
                </div>
              </div>
              <div className="framealive-item-actions">
                {this.state.selected === v.id &&
                  downloading
                }
              </div>
            </div>
          )
        })}
      </div>
    )

    let prevPageButton
    if (page > 1) {
      prevPageButton = (
        <div className="pagination-button" onClick={this.onPageChange('prev')}>
          <i className="fa fa-angle-left" />
          Prev
        </div>
      )
    }

    const pagination = (
      <div className="framealive-pagination-actions">
        {prevPageButton}
        <div className="pagination-button" onClick={this.onPageChange('next')}>
          Next
          <i className="fa fa-angle-right" />
        </div>
      </div>
    )

    return (

      <div className="framealive-collection">
        <Navigation />
        {actions}
        {pagination}
        {videoList}
        {pagination}
        <Popup ref={r => this.popup = r} />
        <Footer />
      </div>
    )
  }
}
