import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import ReactTooltip from 'react-tooltip'

import API from '../api'
import Auth from '../auth'
import settings from '../settings'
import { isMobile, isAndroid } from '../utils'

import Popup from './Popup'

import plusIcon from '../static/img/plus.png'
import miniVideoIcon from '../static/img/minivideo2.png'

import playStoreLogo from '../static/img/play.png'
import iTunesLogo from '../static/img/apple.png'


export class DesktopUploader extends Component {
  render() {
    let btnClass = 'btn btn-primary btn-xl js-scroll-trigger btnUpload'
    if (this.props.creating) {
      btnClass += ' creating'
    }

    return (
      <ul className="list-group shadow">
        <li className="list-group-item flex-column selFile">
          Create a FrameALIVE
        </li>

        <Dropzone
          className="list-group-item flex-column backgroundPhoto"
          activeClassName="dropzone-active"
          accept="image/*"
          multiple={false}
          onDrop={this.props.handleImageDrop}
          data-for="upload-image-message"
          data-tip
        >
          <div className=""></div>
          <div className="rightcontent">
            <i className="fa fa-camera miniLogos" aria-hidden="true"></i>
          </div>
          <div className="plusImage">
        {/*eslint-disable-next-line*/}
            <a onClick={this.props.triggerImagePick}>
              <img src={plusIcon} style={{width: '80px'}} alt="" />
            </a>
          </div>
        </Dropzone>
        <ReactTooltip id="upload-image-message">
          <span> {`Jpg - Max 5 Mb`} </span>
        </ReactTooltip>

        <li className="list-group-item flex-column rightcontent">
          <p className="textSelect">Add a photo:  { this.props.image && this.props.image.name } </p>
        </li>

        <Dropzone
          className="list-group-item flex-column backgroundVideo"
          activeClassName="dropzone-active"
          accept="video/*"
          multiple={false}
          onDrop={this.props.handleVideoDrop}
          data-for="upload-video-message"
          data-tip
        >
          <div className="rightcontent">
            <img src={miniVideoIcon} className="miniLogos" alt="" />
          </div>
          <div className="plusImage">
          {/*eslint-disable-next-line*/}
            <a onClick={this.props.triggerVideoPick}>
              <img src={plusIcon} style={{width: '80px'}} alt="" />
            </a>
          </div>
        </Dropzone>
        <ReactTooltip id="upload-video-message">
          <span> {`.Mp4 or .Mov - Max ${settings.MAX_VIDEO_SIZE} Gb`} </span>
        </ReactTooltip>

        <li className="list-group-item flex-column rightcontent">
          <p className="textSelect">Add a video: { this.props.video && this.props.video.name } </p>
        {/*<small> Or paste a youtube link here: </small>
          <input type="text" value={this.props.youtubeURL} onChange={this.props.handleYoutubeURLChange} />*/}
        </li>

        <li className="list-group-item flex-column" style={{paddingTop: '20px', paddingBottom: '17px'}}>
          <a id="upload-btn" className={btnClass} href="#about" onClick={this.props.handleUploadClick}>
            Create
          </a>
        </li>
      </ul>
    )
  }
}


export class MobileUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingFrames: false,
      selectedFrameIdx: 3,

    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.video && prevProps.video !== this.props.video) {
      this.setState({
        loadingFrames: true,
        selectedFrame: undefined,
      })

      const videoPlayer = document.createElement('video')
      this.frames = []

      this.canvas = document.createElement('canvas')
      this.ctx = this.canvas.getContext('2d')

      videoPlayer.addEventListener('loadedmetadata', this.initCanvas.bind(this), false)
      videoPlayer.addEventListener('loadeddata', this.onLoadedData.bind(this), false)
      videoPlayer.addEventListener('seeked', this.onSeeked.bind(this), false)
      videoPlayer.addEventListener('ended', this.onEnded.bind(this), false)

      videoPlayer.src = URL.createObjectURL(this.props.video)
    }
  }

  initCanvas = e => {
    this.canvas.width = e.target.videoWidth;
    this.canvas.height = e.target.videoHeight;
  }

  onLoadedData = e => {
    e.target.currentTime = 0;
  }

  onSeeked = e => {
    this.captureFrame(e.target)
    if (e.target.currentTime + 1 <= e.target.duration) {
        e.target.currentTime += 1
    }
  }

  captureFrame = video => {
    this.ctx.drawImage(video, 0, 0)
    this.canvas.toBlob(blob => {
      this.frames.push(blob)
      if (!this.props.image && video.currentTime >= 2) {
        this.selectFrame(this.frames.length - 1)
      }
    }, 'image/png', 1.0)
  }

  onEnded = e => {
    this.setState({loadingFrames: false})

    if (!this.state.selectedFrame) {
      const selectedFrameIdx = Math.min(3, this.frames.length - 1)
      this.setState({ selectedFrameIdx })
      this.props.selectImage(this.frames[selectedFrameIdx])
    }
  }

  selectFrame = selectedFrameIdx => {
    const frameName = `${this.props.video.name}-${selectedFrameIdx}.png`
    const frame = new File([this.frames[selectedFrameIdx]], frameName)
    this.setState({ selectedFrameIdx })
    this.props.selectImage(frame)
  }

  selectPrevFrame = () => {
    if (this.state.selectedFrameIdx === 0) {
      return
    }
    const selectedFrameIdx = this.state.selectedFrameIdx - 1
    this.selectFrame(selectedFrameIdx)
  }

  selectNextFrame = () => {
    const selectedFrameIdx = this.state.selectedFrameIdx + 1
    if (selectedFrameIdx > this.frames.length - 1) {
      return
    }
    this.selectFrame(selectedFrameIdx)
  }

  render() {
    let uploader
    if (!this.props.video) {
      uploader = (
        <ul className="list-group shadow uploader">
          <li className="list-group-item flex-column selFile">
            Make a FrameALIVE
            <div className="uploader-sub-msg"> Start by adding a video </div>
          </li>

          <Dropzone
            className="list-group-item flex-column backgroundVideo"
            activeClassName="dropzone-active"
            accept="video/*"
            multiple={false}
            onDrop={this.props.handleVideoDrop}
            data-for="upload-video-message"
            data-tip
          >
            <div className="rightcontent">
              <img src={miniVideoIcon} className="miniLogos" alt="" />
            </div>
            <div className="plusImage">
            {/*eslint-disable-next-line*/}
              <a onClick={this.props.triggerVideoPick}>
                <img src={plusIcon} style={{width: '80px'}} alt="" />
              </a>
            </div>
          </Dropzone>

          <div className="uploader-select-video-msg"> Add a video </div>
        </ul>
      )
    } else {
      uploader = (
        <ul className="list-group shadow uploader">
          <li className="list-group-item flex-column selFile">
            Now choose a Frame to print
            <div className="uploader-sub-msg">
              By scanning this frame with FrameALIVE App you will play your video
            </div>
          </li>

          <li className="list-group-item flex-column">
            <div className="uploader-selected-image">
              {this.props.image &&
                <img src={URL.createObjectURL(this.props.image)} alt="" />
              }
            </div>

            <div className="uploader-image-actions">
              <div className="uploader-image-prev" onClick={this.selectPrevFrame} >
                <i className="fa fa-arrow-circle-left" />
              </div>
              <div className="uploader-image-next" onClick={this.selectNextFrame} >
                <i className="fa fa-arrow-circle-right" />
              </div>
              <div className="uploader-image-select" onClick={this.props.triggerImagePick} >
                <i className="fa fa-plus-circle" />
              </div>
              <div className="uploader-image-actions-msg">
                Or choose another frame / upload a picture
              </div>
            </div>
          </li>

          <li className="list-group-item flex-column" style={{paddingTop: '20px', paddingBottom: '17px'}}>
            <a id="upload-btn" className="btn btn-primary btn-xl js-scroll-trigger btnUpload" href="#about" onClick={this.props.handleUploadClick}>
              Create
            </a>
          </li>
        </ul>
      )
    }

    return uploader
  }
}


export default class Uploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: undefined,
      video: undefined,
      youtubeURL: undefined,
      creating: false,
    }
  }

  getSignedRequest = file => {
    this.popup.show(
      <div className="upload-remaining-msg">
        Checking remaining videos...
      </div>
    )
    API.getSignedRequest(file.name, file.type).then(resp => {
      if (resp.ok) {
        return resp.json()
      } else {
        throw resp
      }
    }).then(resp => {
      this.uploadVideo(file, resp)
    }).catch(resp => {
      //window.alert('ERROR en getSignedRequest!!!');
      if (resp.status === 400) {
        this.popup.show('Invalid video format. Allowed formats are: MOV and MP4', true)
      } else if  (resp.status === 403) {
        this.displayInsufficientErrorMessage()
      } else {
      //  this.popup.show(resp.status, true);
        //window.alert(resp.json());
        this.displayUnexpectedErrorMessage()
      }
      this.onCreationFinished()
    })
  }

  uploadVideo = (file, signedResponse) => {
    API.uploadVideo(signedResponse.data, file, this.onVideoUploadProgress).then(() => {
      //window.alert('llamando al uploadimage')
      this.uploadImage(signedResponse)
    }).catch(error => {
      //window.alert('catch error subiendo video')
      //window.alert(error.description)
      this.popup.show('Could not upload file.', true)
      this.onCreationFinished()
    })
  }

  uploadYoutubeVideo = url => {
    this.popup.show('Processing youtube video. Please wait...')
    API.uploadYoutubeVideo(url).then(resp => {
      if (resp.ok) {
        return resp.json()
      } else {
        throw Error(resp.statusText)
      }
    }).then(resp => {
      this.uploadImage(resp)
    }).catch(error => {
      this.popup.show('Could not upload video.', true)
      this.onCreationFinished()
    })
  }

  uploadImage = frameAliveData => {
    const metadata = {
      rotation: 0,
      identifier: frameAliveData['identifier'],
      original_filename_video: frameAliveData['original_filename_video'],
    }
    API.uploadImage(this.state.image, metadata).then(resp => {
      if (resp.ok) {
        this.displaySuccessMessage()
        this.setState({...this.state, image: undefined, video: undefined, youtubeURL: ''})
      } else if (resp.status === 400) {
        this.popup.show('Invalid video format. Allowed formats are: PNG and JPEG', true)
      } else if (resp.status === 403) {
        this.displayInsufficientErrorMessage()
      } else {
      //  window.alert('ERROR en upload image!!!');
        //window.alert(resp.status);
        //window.alert(resp.json());
        this.displayUnexpectedErrorMessage()
      }
      this.onCreationFinished()
    })
  }

  displaySuccessMessage = () => {
    const downloadFileName = this.state.image.name
    const downloadURL = URL.createObjectURL(this.state.image)

    const reader = new FileReader()
    reader.onload = () => {
      const successMessage = (
        <div className="upload-sucess-msg">
          <div className="title"> Your FrameALIVE will be ready to be scanned within 3 minutes </div>
          <div className="image-preview">
            <img src={reader.result} alt=""/>
          </div>
          <div className="sub-msg"> Check your mailbox within 3 minutes & print your picture! </div>
          <div className="upload-sucess-msg-actions">
            <div className="btn btn-primary">
              <a href={downloadURL} download={downloadFileName}>
                Download
              </a>
            </div>
          </div>
        </div>
      )
      this.popup.show(successMessage, true)
    };
    reader.readAsDataURL(this.state.image)
  }

  displayUnexpectedErrorMessage = () => {
    const message = (
      <div>
        Oops something went wrong! <br/> <br/>
        <small> Please follow the steps from the our <a href="https://framealive.com/user-manual.pdf" target="_blank" rel="noopener noreferrer" style={{color: '#007bff'}}>User Manual</a>.</small> <br/>
        <small> If the error persists contact us at <a href="mailto:info@framealive.com" style={{color: '#007bff'}}>info@framealive.com</a>.</small>
      </div>
    )
    this.popup.show(message, true)
  }

  displayInsufficientErrorMessage = () => {
    const message = (
      <div>
        Oops! You have used all your FrameALIVEs already!
        <br/><br/>
        For more FrameALIVEs click <Link className='fa-request' to='/packages'>here</Link>.
      </div>
    )
    this.popup.show(message, true)
  }

  onVideoUploadProgress = p => {
    const progress = p.loaded / p.total * 100
    const style = {
      width: progress + '%',
      backgroundColor: 'rgb(143, 212, 194)'
    }
    const uploadProgress = (
      <div className="upload-progress">
        <div className="title"> Uploading... </div>
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={style} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    )
    this.popup.show(uploadProgress)
  }

  triggerImagePick = e => {
    e.stopPropagation()
    e.preventDefault()

    if (!Auth.getToken()) {
      this.props.history.push('/login')
      return
    }

    this.imageInput.click()
  }

  triggerVideoPick = e => {
    e.stopPropagation()
    e.preventDefault()

    if (!Auth.getToken()) {
      this.props.history.push('/login')
      return
    }

    this.videoInput.click()
  }

  selectImage = image => {
    const imageSize = image.size / (1024 ** 2)
    if (imageSize > settings.MAX_IMAGE_SIZE) {
      this.popup.show(`Max image size supported is ${settings.MAX_IMAGE_SIZE} MB, selected image is ${imageSize.toFixed(2)} MB.`, true)
      return
    }

    this.setState({ ...this.state, image })
  }

  selectVideo = video => {
    if (video) {

      const videoSize = (video.size / (1000 ** 2))/1000
      if (videoSize > settings.MAX_VIDEO_SIZE) {
        this.popup.show(`Max video size supported is ${settings.MAX_VIDEO_SIZE} GB, selected video is ${videoSize.toFixed(2)} GB.`, true)
        return
      }
    }

    this.setState({ ...this.state, video })
  }

  handleImagePick = e => {
    this.selectImage(e.target.files[0])
  }

  handleImageDrop = images => {
    if (images.length) {
      this.selectImage(images[0])
    }
  }

  handleVideoPick = e => {
    this.selectVideo(e.target.files[0])
  }

  handleVideoDrop = videos => {
    if (videos.length) {
      this.selectVideo(videos[0])
    }
  }

  onCreationFinished = () => {
    this.setState({creating: false})
  }

  handleUploadClick = e => {
    e.stopPropagation()
    e.preventDefault()

    if (this.state.creating) {
      return this.onCreationFinished()
    }

    this.setState({creating: true})

    if (!Auth.getToken()) {
      this.props.history.push('/login')
      return
    }

    const { video, image, youtubeURL } = this.state

    if (!video && !youtubeURL) {
      //this.popup.show('A video or a Youtube URL should be selected.', true)
      this.popup.show('A video should be selected.', true)
      return this.onCreationFinished()
    }

    if (!image) {
      this.popup.show('An image should be selected.', true)
      return this.onCreationFinished()
    }

    if (youtubeURL) {
      this.uploadYoutubeVideo(this.state.youtubeURL)
    } else {
      this.getSignedRequest(this.state.video)
    }
  }

  handleYoutubeURLChange = e => {
    this.setState({ ...this.state, youtubeURL: e.target.value })
  }

  render() {
    const props = {
      image: this.state.image,
      video: this.state.video,
      videoInput: this.videoInput,
      youtubeURL: this.state.youtubeURL,
      creating: this.state.creating,

      handleImageDrop: this.handleImageDrop,
      triggerImagePick: this.triggerImagePick,
      handleVideoDrop: this.handleVideoDrop,
      triggerVideoPick: this.triggerVideoPick,
      handleYoutubeURLChange: this.handleYoutubeURLChange,
      handleUploadClick: this.handleUploadClick,

      selectImage: this.selectImage,
    }

    let uploader
    if (isAndroid()) {
      uploader = <MobileUploader {...props} />
    } else {
      uploader = <DesktopUploader {...props} />
    }

    let downloadApps
    if (isMobile()) {
      let downloadAppsStyle
      if (isAndroid()) {
        // Only display links to download apps while selecting the video.
        if (this.state.video) {
          downloadAppsStyle = {
            display: 'none'
          }
        }
      }

      downloadApps = (
        <div className="download-app-links" style={downloadAppsStyle}>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.diegopiuma.framealive&hl=en" rel="noopener noreferrer">
            <img style={{height: '76px'}} src={playStoreLogo} alt="" />
          </a>
          <a target="_blank" href="https://itunes.apple.com/us/app/framealive/id1264246941" rel="noopener noreferrer">
            <img style={{height: '75px'}} src={iTunesLogo}alt="" />
          </a>
        </div>
      )
    }

    return (
      <div className="col colLeft uploaderRes1">

        {uploader}

        {downloadApps}

        <div style={{height: '0px', overflow: 'hidden'}}>
          <form id="upload-data-form" method="post">
            <input ref={input => this.imageInput = input} onChange={this.handleImagePick} type="file" name="image" accept=".png,.jpg,.jpeg"/>
            <input ref={input => this.videoInput = input} onChange={this.handleVideoPick} type="file" name="video" accept=".mp4,.mov"/>
          </form>
        </div>

        <Popup ref={r => this.popup = r} />
      </div>
    )
  }
}
