import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import API from '../api'
import Auth from '../auth'

import Popup from '../components/Popup'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'


export class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: undefined,
      email: undefined,
      password: undefined,
      tcChecked: false,
    }
  }

  componentWillMount() {
    const token = Auth.getToken()
    if (token) {
      this.props.history.push('/')
    }
  }

  onFieldChange = (field, e) => {
    this.setState({ ...this.state, [field]: e.target.value })
  }

  handleTCChange = e => {
    this.setState({ ...this.state, tcChecked: !this.state.tcChecked })
  }

  handleRegister = e => {
    e.stopPropagation()
    e.preventDefault()

    const { name, email, password, tcChecked } = this.state

    if (!email) {
      this.popup.show('Please insert your email.', true)
      return
    }
    if (!password) {
      this.popup.show('Please insert your password.', true)
      return
    }
    if (!name) {
      this.popup.show('Please insert your name.', true)
      return
    }

    if (!email.includes('@')) {
      this.popup.show('Invalid email format.', true)
      return
    }

    if (!tcChecked) {
      this.popup.show('Please click the checkbox to accept the Terms and Conditions.', true)
      return
    }

    this.popup.show('Creating user...', true)
    API.createUser(name, email, password).then(resp => {
      if (resp.ok) {
          const message = (
            <div>
              <p> An email has been sent to {email} to confirm your email address. </p>
              <small> Make sure to check your spam folder. </small>
            </div>
          )
          this.popup.show(message, true)
      } else {
        this.popup.show('User already exists.', true)
      }
    })
  }

  render() {
    return (
      <div className="backgroundImg">
      <Navigation/>
        <div className="auth-form register-form">
          <div className="row rowLogin">
            <p className="title"> Create your account </p>
          </div>

          <div className="register-input row rowLogin">
            <input type="text" className="inputLogin" name="name" id="rname" placeholder="name" onChange={e => this.onFieldChange('name', e)} />
            <div className="iconoUser">
              <i className="fa fa-user" aria-hidden="true"></i>
            </div>
          </div>

          <div className="row rowLogin">
            <input type="email" className="inputLogin" id="remail" name="username" placeholder="email" onChange={e => this.onFieldChange('email', e)} />
            <div className="iconoUser">
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
          </div>
          <div className="row rowLogin">
            <input type="password" className="inputLogin" id="rpassword" name="password" placeholder="password" onChange={e => this.onFieldChange('password', e)} />
            <div className="iconoUser">
              <i className="fa fa-unlock-alt" aria-hidden="true"></i>
            </div>
          </div>

          <div className="register-input row rowLogin">
            <input id="register-btn" type="submit" className="btnLogin2" name="btnRegisterSubmit" value="Join" onClick={this.handleRegister} />
          </div>

          <div className="register-input row rowLogin">
            <input type="checkbox" id="tandcChecked" name="terms" value={this.state.tcChecked} onChange={this.handleTCChange} />
            I ACCEPT THE <Link to="/terms" style={{color: 'black'}}>TERMS AND CONDITIONS</Link><br/>
          </div>

          <div className="register-input row rowLogin" style={{marginTop: '30px'}}>
            <p className="newAcount">
              Already registered?
              <strong>
                <Link to="/login" style={{color: '#ff3366'}}>  Log in </Link>
              </strong>
            </p>
          </div>
        </div>

        <Popup ref={r => this.popup = r} />
        <Footer/>
      </div>
    )
  }
}


export class Activate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activationToken: props.match.params.token,
      activating: true,
      activationError: false,
      activationSuccessful: false,
    }
  }

  componentWillMount() {
    this.setState({ activating: true })
    API.activateUser(this.state.activationToken).then(resp => {
      if (resp.ok) {
        this.setState({ activating: false, activationSuccessful: true })
      } else {
        this.setState({ activating: false, activationError: true })
      }
    })
  }

  render() {
    let activationForm
    if (this.state.activating) {
      activationForm = (
        <div className="activation-msg">
          Activating your account...
        </div>
      )
    } else if (this.state.activationSuccessful) {
      activationForm = (
        <div className="activation-msg">
          Your account has been successfuly activated!
          <br/><br/>
          <Link to="/login" style={{color: 'black'}}> Login </Link>
          to start creating your FrameALIVEs
        </div>
      )
    } else if (this.state.activationError) {
      activationForm = (
        <div className="activation-msg">
          The activation link is invalid or expired.
        </div>
      )
    }

    return (
      <div className="backgroundImg">
      <Navigation/>
        <div className="auth-form login-form">
          {activationForm}
        </div>
      <Footer/>
      </div>
    )
  }
}


export class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: undefined,
      email: undefined,
      password: undefined,
    }
  }

  componentWillMount() {
    const token = Auth.getToken()
    if (token) {
      this.props.history.push('/')
    }
  }

  onFieldChange = (field, e) => {
    this.setState({ ...this.state, [field]: e.target.value })
  }

  handleLogin = e => {
    e.stopPropagation()
    e.preventDefault()

    const { email, password } = this.state

    if (!email) {
      this.popup.show('Please insert your email.', true)
      return
    }
    if (!password) {
      this.popup.show('Please insert your password.', true)
      return
    }

    Auth.removeToken()

    API.getToken(email, password).then(resp => {
      if (resp.ok) {
        return resp.json()
      } else {
        throw resp
      }
    }).then(({ token, admin }) => {
      if (token) {
        Auth.setToken(token, admin)
        this.props.history.push('/')
      }
    }).catch(resp => {
      if (resp.status === 403) {
        this.popup.show('You have to activate your account first, check your mailbox. ', true)
      } else {
        this.popup.show('Invalid credentials. Please verify your email and password.', true)
      }
    })
  }

  render() {
    return (
      <div className="backgroundImg">
      <Navigation/>
        <div className="auth-form login-form">
          <div className="row rowLogin">
            <p className="title"> Welcome back! </p>
          </div>

          <div className="row rowLogin">
            <input type="email" className="inputLogin" id="remail" name="username" placeholder="email" onChange={e => this.onFieldChange('email', e)} />
            <div className="iconoUser">
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
          </div>
          <div className="row rowLogin">
            <input type="password" className="inputLogin" id="rpassword" name="password" placeholder="password" onChange={e => this.onFieldChange('password', e)} />
            <div className="iconoUser">
              <i className="fa fa-unlock-alt" aria-hidden="true"></i>
            </div>
          </div>

          <div className="login-input row rowLogin">
            <input id="login-btn" type="submit" className="btnLogin2" name="btnSubmit" value="Log in" onClick={this.handleLogin} />
          </div>

          <div className="login-input row rowLogin" style={{marginTop: '30px'}}>
            <p className="newAcount">
              Need an account?
              <strong>
                <Link to="/register" style={{color: '#ff3366'}}> Join </Link>
              </strong>
            </p>
          </div>

          <div className="row rowLogin" style={{marginTop: '30px'}}>
            <p className="newAcount">
              <Link to="/reset" style={{color: 'black'}}> Forgot your password? </Link>
            </p>
          </div>
        </div>

        <Popup ref={r => this.popup = r} />
        <Footer/>
      </div>
    )
  }
}


export class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: undefined,
      password: undefined,
      passwordConfirmation: undefined,
      // Process steps:
      //  'request': form to request reset token
      //  'sent': reset token sent to user email
      //  'validate': validates reset token pass by user
      //  'invalid_link'
      //  'reset': form to set new passworkd
      //  'updated': notify user that passowrd was succesfully updated
      step: props.match.params.token ? 'validate' : 'request',
      token: props.match.params.token
    }
  }

  componentWillMount() {
    if (this.state.step === 'validate') {
      API.validateResetPassToken(this.state.token).then(resp => {
        if (resp.ok) {
          this.setState({ step: 'reset' })
        } else {
          if (resp.status === 404) {
            this.setState({ step: 'invalid_link' })
          }
          // TODO: Other errors..
        }
      })
    }
  }

  onFieldChange = (field, e) => {
    this.setState({ ...this.state, [field]: e.target.value })
  }

  handleRequestResetToken = () => {
    API.getResetPassToken(this.state.email).then(resp => {
      if (resp.ok) {
        this.setState({ step: 'sent' })
      } else {
        if (resp.status === 404) {
          this.popup.show('Invalid email!', true)
        }
      }
    })
  }

  handleReset = () => {
    if (!this.state.password) {
      this.popup.show("Password can't be empty !", true)
      return
    }

    if (this.state.password !== this.state.passwordConfirmation) {
      this.popup.show('Both password should be the same!', true)
      return
    }

    API.resetPassword(this.state.token, this.state.password).then(resp => {
      if (resp.ok) {
        return resp.json()
      } else {
        throw resp
      }
    }).then(resp => {
      this.setState({ step: 'updated', email: resp.email })
    }).catch(resp => {
      if (resp.status === 404) {
        this.popup.show('User not found!', true)
      }
    })
  }

  handleLogin = () => {
    API.getToken(this.state.email, this.state.password).then(resp => {
      if (resp.ok) {
        return resp.json()
      } else {
        throw resp
      }
    }).then(({ token, admin }) => {
      if (token) {
        Auth.setToken(token, admin)
        this.props.history.push('/')
      }
    }).catch(resp => {
      if (resp.status === 403) {
        this.popup.show('You have to activate your account first, check your mailbox. ', true)
      } else {
        this.popup.show('Invalid credentials. Please verify your email and password.', true)
      }
    })
  }

  render() {
    let content
    switch (this.state.step) {
      case 'request': {
        content = (
          <div>
            <div className="row rowLogin">
              <input type="email" className="inputLogin" placeholder="email" onChange={e => this.onFieldChange('email', e)} />
              <div className="iconoUser">
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
              </div>
            </div>

            <div className="login-input row rowLogin">
              <input type="submit" className="btnLogin2" value="Send" onClick={this.handleRequestResetToken} />
            </div>
          </div>
        )
        break
      }
      case 'sent': {
        content = (
          <div>
            Check your mailbox! <br/>
            We sent you an email with a link to change your password.
          </div>
        )
        break
      }
      case 'validate': {
        content = (
          <div>
            Validating link...
          </div>
        )
        break
      }
      case 'invalid_link': {
       content = (
          <div>
            The link is invalid or expired.
          </div>
        )
        break
      }
      case 'reset': {
        content = (
          <div>
            <div className="row rowLogin">
              <input
                type="password"
                className="inputLogin"
                placeholder="new password"
                onChange={e => this.onFieldChange('password', e)}
              />
              <div className="iconoUser">
                <i className="fa fa-unlock-alt" aria-hidden="true"></i>
              </div>
            </div>
            <div className="row rowLogin">
              <input
                type="password"
                className="inputLogin"
                placeholder="confirm new password "
                onChange={e => this.onFieldChange('passwordConfirmation', e)}
              />
              <div className="iconoUser">
                <i className="fa fa-unlock-alt" aria-hidden="true"></i>
              </div>
            </div>

            <div className="login-input row rowLogin">
              <input type="submit" className="btnLogin2" value="Change password" onClick={this.handleReset} />
            </div>
          </div>
        )
        break
      }
      case 'updated': {
        content = (
          <div>
            Your password has been succesfully updated!
            <div className="login-input row rowLogin">
              <input type="submit" className="btnLogin2" value="Login" onClick={this.handleLogin} />
            </div>
          </div>
        )
        break
      }
      default:{ break}
    }

    return (
      <div className="backgroundImg">
      <Navigation/>
        <div className="auth-form login-form">
          <div className="row rowLogin">
            <p className="title"> Reset password </p>
          </div>
          {content}
        </div>
        <Popup ref={r => this.popup = r} />
        <Footer/>
      </div>
    )
  }
}
