import Auth from './auth'
import settings from './settings'
import { uploadToS3 } from './utils'


class API {

  constructor() {
    this.baseURL = settings.API_URL
    this.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

  createFormData(fields) {
    let formData = new FormData()
    for (var key in fields) {
      formData.append(key, fields[key])
    }
    return formData
  }

  getToken(email, password) {
    return fetch(`${this.baseURL}/api/token`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ email, password }),
    })
  }

  createUser(name, email, password) {
    return fetch(`${this.baseURL}/api/users`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ name, email, password }),
    })
  }

  getSignedRequest(name, type) {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/sign_s3?file_name=${encodeURIComponent(name)}&file_type=${type}`, {
      headers: { token }
    })
  }

  uploadVideo({ url, fields }, video, onProgress) {
    let formData = this.createFormData(fields)
    formData.append('file', video)

    const options = {
      method: 'POST',
      headers: {
        'x-amz-acl': 'public-read',
      },
      body: formData
    }

    return uploadToS3(url, options, onProgress)
  }

  uploadImage(image, metadata) {
    const token = Auth.getToken()

    let formData = this.createFormData(metadata)
    formData.append('file', image)

    return fetch(`${this.baseURL}/api/upload_image`, {
      method: 'POST',
      headers: { token },
      body: formData
    })
  }

  downloadImage(id) {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/download_image/${id}`, {
      headers: { token }
    })
  }

  fetchVideos(page=1) {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/videos?page=${page}`, {
      headers: { token }
    })
  }

  deleteVideo(id, title) {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/visuals/delete`, {
      method: 'DELETE',
      headers: { ...this.headers, token },
      body: JSON.stringify({ id, title })
    })
  }

  uploadYoutubeVideo(url) {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/upload_video`, {
      method: 'POST',
      headers: { ...this.headers, token },
      body: JSON.stringify({ url })
    })
  }

  activateUser(token) {
    return fetch(`${this.baseURL}/api/user/activate/${token}`)
  }

  getResetPassToken(email) {
    return fetch(`${this.baseURL}/api/user/reset/token`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ email })
    })
  }

  validateResetPassToken(token) {
    return fetch(`${this.baseURL}/api/user/reset/${token}`)
  }

  resetPassword(token, password) {
    return fetch(`${this.baseURL}/api/user/reset`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ token, password })
    })
  }

  fetchRemainingCount() {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/remaining_videos`, {
      headers: { token }
    })
  }

  getPromo(code) {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/promo`, {
      method: 'POST',
      headers: { ...this.headers, token },
      body: JSON.stringify({ code })
    })
  }

  applyPromo(code) {
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/promo/apply`, {
      method: 'POST',
      headers: { ...this.headers, token },
      body: JSON.stringify({ code })
    })
  }

  getStreamingUrl(code) {
    //window.alert("en api.js dentro del getStreamingUrl");
    //window.alert(this.baseURL);
    const token = Auth.getToken()
    return fetch(`${this.baseURL}/api/streaming_url`, {
      method: 'POST',
      headers: { ...this.headers, token },
      body: JSON.stringify({ code })
    })
  }
}

export default new API()
